<template>
    <div id="app">
      <component :is="layout">
      <transition :name="transitionName" mode="out-in">
        <AlertMessage v-if="alertamen" :color="colores" :message="mensajess"/>
      <router-view></router-view></transition>
    </component>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
    import { localize } from 'vee-validate';
    import es from 'vee-validate/dist/locale/es';
    import i18n from "@/i18n";
import firebase from '@/services/firebase';
import Cookies from 'js-cookie';
import AlertMessage from "./components/componente/AlertMessage.vue";


    localize({es});

    export default {
      name: 'App',
      components: {
        AlertMessage,
        DefaultLayout: () => import('@/layouts/Default.vue'),
        ErrorLayout: () => import('@/layouts/Error.vue'),
        AdminClubLayout: () => import('@/layouts/AdminClub.vue'),
      },
      data: () => ({
        transitionName: 'fade',
        intervalOnActive: null,
        alertamen:false,
        colores: '',
        mensajess:'',
        intervalOnIdle: null,
        timeout: null,
      }),
      metaInfo() {
        return {
          titleTemplate: `%s`,
          htmlAttrs: {
            lang: i18n.t("config.lang")
          }
        }
      },
      created() {
        this.$router.afterEach((to, from) => {
          if (to.meta.layout !== from.meta.layout && this.getUsuario) {
            this.clearNotifications()
          }
        })
        this.$vuetify.theme.dark = false
      },
      watch: {
        $route(to, from) {
          // Determina la transición en función de la ruta actual y la ruta anterior
          if (to.name === 'ComponenteB' && from.name === 'ComponenteA') {
            this.transitionName = 'fade-componente-b-desde-a';
          } else {
            this.transitionName = 'fade'; // Transición predeterminada
          }
        }
      },
      mounted: function () {
        this.checkAppVersion();

        this.resolveResolution();
        this.checkLastActive();

      },
      methods: {
        ...mapActions([
          'updateResolution',
        ]),
        ...mapActions("ui", [
          "setError",
          "clearNotifications",
        ]),
        ...mapActions("session", ["logout", "setUserToken"]),
        checkAppVersion() {
          const storedVersion = localStorage.getItem('appVersion');
          const APP_VERSION = process.env.VUE_APP_VERSION;

          if (storedVersion === null || storedVersion === undefined || storedVersion === "undefined"  ) {
            localStorage.setItem('appVersion', APP_VERSION);
          } else if (storedVersion !== APP_VERSION) {
            // Si la versión ha cambiado, fuerza una recarga
            localStorage.setItem('appVersion', APP_VERSION);
            // Opción 1: Forzar la recarga del navegador para cargar la nueva versión
            window.location.reload(true);

            // Opción 2: Mostrar un mensaje al usuario pidiendo que recargue la página
            // this.$notify({ type: 'info', message: 'Nueva versión disponible. Por favor, recargue la página.' });
          }
        },
        logoutAndClearSession() {
          this.logout(); // Tu función de logout
          this.setError({
            message: 'Tu sesión ha expirado por inactividad'
          });
          this.alertamen = true;
          this.colores = 'error';
          this.mensajess = 'Tu sesión ha expirado por inactividad';
          localStorage.clear(); // Limpia el localStorage
          Cookies.remove('token'); // Borra la cookie del token
          window.location.href = '/';
        },
        checkLastActive() {
          const token = Cookies.get('token') ? JSON.parse(Cookies.get('token')) : null;
          if(token !== null){
            if (token && this.getUsuario) {
              const lastActive = localStorage.getItem('lastActive')
                  ? parseInt(localStorage.getItem('lastActive'), 10)
                  : null;
              const currentUnixTime = Math.floor(new Date().getTime() / 1000);

              if (lastActive && token.exp < currentUnixTime) {
                this.logoutAndClearSession();
              }
            }
          }else{
            this.logout();
          }
        },
        resolveResolution() {
          const size = window.innerWidth;
          this.isMobile = size <= 768;
          this.updateResolution(this.isMobile);
        },

      },
      onActive() {
        clearTimeout(this.intervalOnIdle)

        const token = Cookies.get('token')
            ? JSON.parse(Cookies.get('token'))
            : null

        if (token && this.getUsuario) {
          clearInterval(this.intervalOnActive)

          this.intervalOnActive = setInterval(() => {
            const checkToken = Cookies.get('token')
                ? JSON.parse(Cookies.get('token'))
                : null

            const currentUnixTime = Math.floor(new Date().getTime() / 1000);

            localStorage.setItem('lastActive', String(currentUnixTime))
            if (checkToken) {
              const isTokenCloseToExpired = checkToken.exp - currentUnixTime < 60 * 50;
              if (isTokenCloseToExpired) {
                firebase.auth().onAuthStateChanged(async (user) => {
                  if (user) {
                    try {
                      await user.getIdToken(true);
                      const newTokenData = await user.getIdTokenResult();
                      if (newTokenData) {
                        this.setUserToken({
                          tokenId: newTokenData.token,
                          expirationTime: newTokenData.expirationTime,
                          exp: newTokenData.claims.exp
                        })
                      }
                    } catch (error) {
                      this.logoutAndClearSession();
                    }
                  } else {
                    this.logoutAndClearSession();
                  }
                })
              }

            }

          }, 1000)
        }
      },
      onIdle() {
        clearInterval(this.intervalOnActive)

        const token = Cookies.get('token')
            ? JSON.parse(Cookies.get('token'))
            : null
        if(token !== null){
          if (token && this.getUsuario) {
            clearInterval(this.intervalOnIdle)

            this.intervalOnIdle = setInterval(() => {
              const currentUnixTime = localStorage.getItem('lastActive')
                  ? localStorage.getItem('lastActive')
                  : new Date().getTime() / 1000

              const tokenIsExpired = token.exp < currentUnixTime

              if (tokenIsExpired) {
                this.logoutAndClearSession();
              }
            }, 1000)
          }}else{
          this.logout();
        }
      },

      computed: {
        ...mapState('ui', ['error', 'success']),
        ...mapGetters('session', ['getUser','getUsuario']),
        layout() {
          return this.$route.meta.layout || 'DefaultLayout';
        },
      }
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-componente-a-desde-b-enter-active,
.fade-componente-b-desde-a-enter-active,
.fade-componente-a-desde-b-leave-active,
.fade-componente-b-desde-a-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}
.fade-componente-a-desde-b-enter, .fade-componente-b-desde-a-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.fade-componente-a-desde-b-leave-active,
.fade-componente-b-desde-a-enter {
  opacity: 0;
  transform: translateX(-30px);
}
    .loading {
        position: fixed;
        z-index: 100000;
        height: 2em;
        width: 2em;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .loading:before {
        content: '';
        display: block;
        position: fixed;
        margin: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color:rgba(0,0,0,0.45);
    }
    .cursor-pointer{
        cursor: pointer;
    }

.raleway-fam {
            font-family: "Raleway", sans-serif;
            font-optical-sizing: auto;
            font-weight: <weight>;
            font-style: normal;
          }

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType-ExtraLight.eot');
      src: local('Brutal Type Extra Light'), local('BrutalType-ExtraLight'),
      url('./fonts/BrutalType-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType-ExtraLight.woff2') format('woff2'),
      url('./fonts/BrutalType-ExtraLight.woff') format('woff'),
      url('./fonts/BrutalType-ExtraLight.ttf') format('truetype');
      font-weight: 200;
      font-style: normal;
    }

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType-Medium.eot');
      src: local('Brutal Type Medium'), local('BrutalType-Medium'),
      url('./fonts/BrutalType-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType-Medium.woff2') format('woff2'),
      url('./fonts/BrutalType-Medium.woff') format('woff'),
      url('./fonts/BrutalType-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
    }

@font-face {
  font-family: 'ITCFRANKLINGOTHICSTD-BOOK';
  src: url('./fonts/ITCFRANKLINGOTHICSTD-BOOK.woff') format('woff'),
  url('./fonts/ITCFRANKLINGOTHICSTD-BOOK.OTF') format('opentype'),
  url('./fonts/ITCFRANKLINGOTHICSTD-BOOK.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType-Bold.eot');
      src: local('Brutal Type Bold'), local('BrutalType-Bold'),
      url('./fonts/BrutalType-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType-Bold.woff2') format('woff2'),
      url('./fonts/BrutalType-Bold.woff') format('woff'),
      url('./fonts/BrutalType-Bold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
    }

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType-Thin.eot');
      src: local('Brutal Type Thin'), local('BrutalType-Thin'),
      url('./fonts/BrutalType-Thin.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType-Thin.woff2') format('woff2'),
      url('./fonts/BrutalType-Thin.woff') format('woff'),
      url('./fonts/BrutalType-Thin.ttf') format('truetype');
      font-weight: 100;
      font-style: normal;
    }

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType-ExtraBold.eot');
      src: local('Brutal Type Extra Bold'), local('BrutalType-ExtraBold'),
      url('./fonts/BrutalType-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType-ExtraBold.woff2') format('woff2'),
      url('./fonts/BrutalType-ExtraBold.woff') format('woff'),
      url('./fonts/BrutalType-ExtraBold.ttf') format('truetype');
      font-weight: 800;
      font-style: normal;
    }

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType-Black.eot');
      src: local('Brutal Type Black'), local('BrutalType-Black'),
      url('./fonts/BrutalType-Black.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType-Black.woff2') format('woff2'),
      url('./fonts/BrutalType-Black.woff') format('woff'),
      url('./fonts/BrutalType-Black.ttf') format('truetype');
      font-weight: 900;
      font-style: normal;
    }

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType-Light.eot');
      src: local('Brutal Type Light'), local('BrutalType-Light'),
      url('./fonts/BrutalType-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType-Light.woff2') format('woff2'),
      url('./fonts/BrutalType-Light.woff') format('woff'),
      url('./fonts/BrutalType-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
    }

    @font-face {
      font-family: 'Brutal Type';
      src: url('./fonts/BrutalType.eot');
      src: local('Brutal Type'), local('BrutalType'),
      url('./fonts/BrutalType.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BrutalType.woff2') format('woff2'),
      url('./fonts/BrutalType.woff') format('woff'),
      url('./fonts/BrutalType.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
    }


@font-face {
  font-family: 'LGSmartUI';
  src: url('./fonts/LGSmartUI/LGSmartUI-Regular.eot');
  src: local('LGSmartUI-Regular'), local('LGSmartUI-Regular'),
  url('./fonts/LGSmartUI/LGSmartUI-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGSmartUI/LGSmartUI-Regular.woff2') format('woff2'),
  url('./fonts/LGSmartUI/LGSmartUI-Regular.woff') format('woff'),
  url('./fonts/LGSmartUI/LGSmartUI-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'LGSmartUI';
  src: url('./fonts/LGSmartUI/LGSmartUI-Light.eot');
  src: local('LGSmartUI-Light'), local('LGSmartUI-Light'),
  url('./fonts/LGSmartUI/LGSmartUI-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGSmartUI/LGSmartUI-Light.woff2') format('woff2'),
  url('./fonts/LGSmartUI/LGSmartUI-Light.woff') format('woff'),
  url('./fonts/LGSmartUI/LGSmartUI-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'LGSmartUI';
  src: url('./fonts/LGSmartUI/LGSmartUI-SemiBold.eot');
  src: local('LGSmartUI-SemiBold'), local('LGSmartUI-SemiBold'),
  url('./fonts/LGSmartUI/LGSmartUI-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGSmartUI/LGSmartUI-SemiBold.woff2') format('woff2'),
  url('./fonts/LGSmartUI/LGSmartUI-SemiBold.woff') format('woff'),
  url('./fonts/LGSmartUI/LGSmartUI-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'LGSmartUI';
  src: url('./fonts/LGSmartUI/LGSmartUI-Bold.eot');
  src: local('LGSmartUI-Bold'), local('LGSmartUI-Bold'),
  url('./fonts/LGSmartUI/LGSmartUI-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGSmartUI/LGSmartUI-Bold.woff2') format('woff2'),
  url('./fonts/LGSmartUI/LGSmartUI-Bold.woff') format('woff'),
  url('./fonts/LGSmartUI/LGSmartUI-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
    /* cyrillic-ext */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2') format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2') format('woff2');
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* greek-ext */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2') format('woff2');
      unicode-range: U+1F00-1FFF;
    }
    /* greek */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2') format('woff2');
      unicode-range: U+0370-03FF;
    }
    /* hebrew */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2') format('woff2');
      unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
    }
    /* vietnamese */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2') format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2') format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-stretch: 100%;
      font-display: swap;
      src: url('./fonts/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2') format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: 'Futura Bk BT';
      src: url('./fonts/FuturaBT-Book.eot');
      src: url('./fonts/FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
      url('./fonts/FuturaBT-Book.woff2') format('woff2'),
      url('./fonts/FuturaBT-Book.woff') format('woff'),
      url('./fonts/FuturaBT-Book.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'ProLamina';
      src: url('./fonts/ProLamina.eot');
      src: url('./fonts/ProLamina.eot?#iefix') format('embedded-opentype'),
      url('./fonts/ProLamina.woff2') format('woff2'),
      url('./fonts/ProLamina.woff') format('woff'),
      url('./fonts/ProLamina.ttf') format('truetype');
    }
    @font-face {
      font-family: 'ModularAmplitude';
      src: url('./fonts/ModularAmplitude.eot');
      src: url('./fonts/ModularAmplitude.eot?#iefix') format('embedded-opentype'),
      url('./fonts/ModularAmplitude.woff2') format('woff2'),
      url('./fonts/ModularAmplitude.woff') format('woff'),
      url('./fonts/ModularAmplitude.ttf') format('truetype');
    }
    @font-face {
      font-family: 'Origicide';
      src: url('./fonts/Origicide.eot');
      src: url('./fonts/Origicide.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Origicide.woff2') format('woff2'),
      url('./fonts/Origicide.woff') format('woff'),
      url('./fonts/Origicide.svg') format('svg'),
      url('./fonts/Origicide.TTF') format('truetype');
    }
    @font-face {
      font-family: 'PlanetoftheApes';
      src: url('./fonts/PlanetoftheApes.eot');
      src: url('./fonts/PlanetoftheApes.eot?#iefix') format('embedded-opentype'),
      url('./fonts/PlanetoftheApes.woff2') format('woff2'),
      url('./fonts/PlanetoftheApes.woff') format('woff'),
      url('./fonts/PlanetoftheApes.svg') format('svg'),
      url('./fonts/PlanetoftheApes.ttf') format('truetype');
    }
    @font-face {
      font-family: 'GothamBlack';
      src: url('./fonts/GothamBlack.eot');
      src: url('./fonts/GothamBlack.eot?#iefix') format('embedded-opentype'),
      url('./fonts/GothamBlack.woff2') format('woff2'),
      url('./fonts/GothamBlack.woff') format('woff'),
      url('./fonts/GothamBlack.svg') format('svg'),
      url('./fonts/GothamBlack.ttf') format('truetype');
    }
@font-face {
  font-family: 'LG EI';
  src: url('./fonts/LGEI/LGEIText-Bd-TTF.eot');
  src: local('LG EI Text Bold TTF'), local('LGEIText-Bd-TTF'),
  url('./fonts/LGEI/LGEIText-Bd-TTF.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGEI/LGEIText-Bd-TTF.woff2') format('woff2'),
  url('./fonts/LGEI/LGEIText-Bd-TTF.woff') format('woff'),
  url('./fonts/LGEI/LGEIText-Bd-TTF.ttf') format('truetype'),
  url('./fonts/LGEI/LGEIText-Bd-TTF.svg#LGEIText-Bd-TTF') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LG EI';
  src: url('./fonts/LGEI/LGEIText-Sb-TTF.eot');
  src: local('LG EI Text Semibold TTF'), local('LGEIText-Sb-TTF'),
  url('./fonts/LGEI/LGEIText-Sb-TTF.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGEI/LGEIText-Sb-TTF.woff2') format('woff2'),
  url('./fonts/LGEI/LGEIText-Sb-TTF.woff') format('woff'),
  url('./fonts/LGEI/LGEIText-Sb-TTF.ttf') format('truetype'),
  url('./fonts/LGEI/LGEIText-Sb-TTF.svg#LGEIText-Sb-TTF') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'LG EI';
  src: url('./fonts/LGEI/LGEIText-Rg-TTF.eot');
  src: local('LG EI Text Regular TTF'), local('LGEIText-Rg-TTF'),
  url('./fonts/LGEI/LGEIText-Rg-TTF.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGEI/LGEIText-Rg-TTF.woff2') format('woff2'),
  url('./fonts/LGEI/LGEIText-Rg-TTF.woff') format('woff'),
  url('./fonts/LGEI/LGEIText-Rg-TTF.ttf') format('truetype'),
  url('./fonts/LGEI/LGEIText-Rg-TTF.svg#LGEIText-Rg-TTF') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LG EI';
  src: url('./fonts/LGEI/LGEIText-Lt-TTF.eot');
  src: local('LG EI Text Light TTF'), local('LGEIText-Lt-TTF'),
  url('./fonts/LGEI/LGEIText-Lt-TTF.eot?#iefix') format('embedded-opentype'),
  url('./fonts/LGEI/LGEIText-Lt-TTF.woff2') format('woff2'),
  url('./fonts/LGEI/LGEIText-Lt-TTF.woff') format('woff'),
  url('./fonts/LGEI/LGEIText-Lt-TTF.ttf') format('truetype'),
  url('./fonts/LGEI/LGEIText-Lt-TTF.svg#LGEIText-Lt-TTF') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}




</style>
